import React, { useEffect } from "react"
import { motion, AnimatePresence } from "framer-motion"

interface Props {
	children: React.ReactNode
	location: {
		pathname:string
	}
}

const PageTransition = (props: Props) => {

	const { children, location } = props

	useEffect(() => {

		window.scrollTo(0,0)
	
	}, [props])

	return (

		<div className="PageContainer">

			<AnimatePresence >

				<motion.div key={location.pathname} initial={{opacity: 0, x: -30}} exit={{ opacity: 0, x: -30,}} animate={{ opacity: 1, x: 0,}}>
					{children}
				</motion.div>

			</AnimatePresence>

		</div>

	)

}

export default PageTransition
